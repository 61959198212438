<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
        </button>
        <span>{{title}}</span>

        <span class="title2 right-aligner">{{title2}}</span>
        <span class="right-aligner"></span>

        <span>
            <a mat-icon-button href="mailto:support@micsto.com" #tooltip="matTooltip" matTooltip="Support"
                matTooltipPosition="below" matTooltipShowDelay="400" matTooltipHideDelay="50">
                <mat-icon>contact_support</mat-icon>
            </a>

            <a mat-icon-button href="mailto:info@micsto.com" #tooltip="matTooltip" matTooltip="Contact"
                matTooltipPosition="below" matTooltipShowDelay="400" matTooltipHideDelay="50">
                <mat-icon>alternate_email</mat-icon>
            </a>

            <a mat-icon-button [routerLink]="'legal_disclosure'" #tooltip="matTooltip" matTooltip="Legal disclosure"
                matTooltipPosition="below" matTooltipShowDelay="400" matTooltipHideDelay="50">
                <mat-icon>policy</mat-icon>
            </a>

            <a *ngIf="app.loggedInUserName" mat-icon-button [routerLink]="'user_account'" #tooltip="matTooltip" matTooltip="My Account"
                matTooltipPosition="below" matTooltipShowDelay="400" matTooltipHideDelay="50">
                <mat-icon>manage_accounts</mat-icon>
            </a>
        </span>
    </mat-toolbar-row>

    <mat-toolbar-row>
        <button mat-button [routerLink]="'extensions'">
            <mat-icon>extension</mat-icon>
            <span>Extensions</span>
        </button>

        <button mat-button [routerLink]="'support-packages'">
            <mat-icon>support_agent</mat-icon>
            <span>Support packages</span>
        </button>

        <button mat-button [routerLink]="'downloads'">
            <mat-icon>download</mat-icon>
            <span>Downloads</span>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <a mat-menu-item [routerLink]="'/extensions'">
        <mat-icon>extension</mat-icon>
        <span>Extensions</span>
    </a>
    <a mat-menu-item [routerLink]="'/support-packages'">
        <mat-icon>support_agent</mat-icon>
        <span>Support packages</span>
    </a>
    <a mat-menu-item [routerLink]="'/downloads'">
        <mat-icon>download</mat-icon>
        <span>Downloads</span>
    </a>
    <mat-divider></mat-divider>

  <a *ngIf="!app.loggedInUserName" mat-menu-item [routerLink]="'/login'">
    <mat-icon>login</mat-icon>
    <span>Login</span>
  </a>

  <a *ngIf="app.loggedInUserName" mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Log out {{app.loggedInUserName}}</span>
  </a>
</mat-menu>