// Import Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Import Klassen
import { App } from '../_class/app';

@Component({
  selector: 'app-login',
  standalone: false,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  name: string = "";
  password: string = "";

  // Konstruktor
  constructor(public app: App, private router: Router) {
  }

  canLogin() {
    if (!this.name) {
      return false;
    }

    if (!this.password) {
      return false;
    }

    return true;
  }

  async login() {
    if (this.canLogin()) {
      //this.app.showProgress(true);
      let ret = await this.app.login(this.name, this.password);
      //this.app.showProgress(false);

      if (ret.ok) {
        if (window.history.length > 1){
          window.history.back();
        } else {
          this.router.navigateByUrl(`/extensions`);
        }        
      } else {
        this.app.showError(ret.msg);
      }
    }
  }
}
