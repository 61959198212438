import { Component, Input } from '@angular/core';
import { IDownload } from '../_interface/download-interface';

import { App } from '../_class/app';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrl: './download.component.scss'
})
export class DownloadComponent {
  @Input() download: IDownload;

  constructor(private app: App){
      this.download = {} as IDownload;
  }

  getURL(): string {
    let url = this.download.downloadURL;

    if (this.download.type == App.DOWNLOAD_TYPE_SOURCECODE && this.app.accessToken){
      url += `&token=${this.app.accessToken}`
    }

    return url;
  }
}
