<div class="main-content">
  <div class="top">
    <mat-card class="top-card" appearance="outlined">
      <mat-card-header class="back-color-secondary">
        <mat-card-title>
          <mat-icon>manage_accounts</mat-icon>
          <span style="padding-left: 12px">My account</span>
          <span class="right-aligner"></span>
          <mat-icon>check</mat-icon>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="main">
    <div *ngIf="app.accessToken" class="section-container">
      <div class="field-container-vert">
        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!user.name">
          <mat-label>User name</mat-label>
          <input required matInput [(ngModel)]="user.name" autocomplete="username">
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!user.email">
          <mat-label>E-mail</mat-label>
          <input required email matInput [(ngModel)]="user.email" type="email" autocomplete="email">
        </mat-form-field>
      </div>

      <div class="field-container-vert">
        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!user.firstName">
          <mat-label>First name</mat-label>
          <input required matInput [(ngModel)]="user.firstName" autocomplete="given-name">
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!user.lastName">
          <mat-label>Last name</mat-label>
          <input required matInput [(ngModel)]="user.lastName" autocomplete="family-name">
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <mat-label>Company</mat-label>
          <input matInput [(ngModel)]="user.company" autocomplete="organization">
        </mat-form-field>
      </div>

      <div *ngIf="supportPackages.length" class="card-container">
        <div class="section-title">My Support Packages</div>
        <div class="card-container">
          <div *ngFor="let item of supportPackages">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{item.name}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div *ngIf="item.warningText" class="warning">
                  <mat-icon>warning</mat-icon>
                  <div class="warning-text">{{item.warningText}}</div>
                </div>

                <div>
                  <span>Type: {{item.type}}</span>
                </div>

                <div *ngIf="isSubscription(item)">
                  <span>Term: {{item.termBegin}} - {{item.termEnd}}</span>
                </div>

                <div *ngIf="isOneTime(item)">
                  <span>For version: {{item.oneTimeVersion}}</span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!app.accessToken" class="field-container-vert">
      <div class="placeholder-text">You are not logged in</div>

      <div class="container-horz-hcenter-vcenter">
        <button mat-raised-button color="primary" [routerLink]="'/login'">
          <mat-icon>login</mat-icon>
          Login
        </button>
      </div>
    </div>
  </div>
</div>