import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { App } from '../_class/app';

import { IUser } from '../_interface/user-interface';
import { IUserSupportPackage } from '../_interface/user-support-package-interface';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrl: './user-account.component.scss'
})
export class UserAccountComponent {
  user: IUser = {} as IUser;
  supportPackages: Array<IUserSupportPackage> = new Array<IUserSupportPackage>;

  constructor(public app: App, private route: ActivatedRoute){

  }

  ngOnInit(){
    // Daten übernehmen
    this.user = this.route.snapshot.data['loggedinuser'];    
    this.supportPackages = this.route.snapshot.data['supportpackages'];
  }

  isOneTime(usp: IUserSupportPackage){
    let bIs = false;

    if (usp.type == App.SUPPORT_PACKAGE_TYPE_ONE_TIME){
      bIs = true;
    }

    return bIs;
  }

  isSubscription(usp: IUserSupportPackage){
    let bIs = false;

    if (usp.type == App.SUPPORT_PACKAGE_TYPE_SUBSCRIPTION){
      bIs = true;
    }

    return bIs;
  }
}
