// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Import Schnittstellen
import { IApiReturn } from '../_interface/api-return-interface';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getDownloads(accessToken?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=get_downloads" + (accessToken ? `&token=${accessToken}` : '');

    let body = '';
    if (accessToken) {
      body = JSON.stringify({access_token: accessToken});
    }
    
    return this.http.post<IApiReturn>(url, body);
  }

  getLoggedInUser(accessToken?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=get_logged_in_user" + (accessToken ? `&token=${accessToken}` : '');

    let body = '';
    return this.http.post<IApiReturn>(url, body);
  }

  login(user: string, password: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=login";

    let body = JSON.stringify({name: user, password: password});
    return this.http.post<IApiReturn>(url, body);
  }

  logout(accessToken?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=logout" + (accessToken ? `&token=${accessToken}` : '');

    let body = '';
    return this.http.post<IApiReturn>(url, body);
  }

  select(select: any): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=sql&type=query";
    return this.http.post<IApiReturn>(url, select);
  }
}
