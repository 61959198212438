import { Component, OnInit } from '@angular/core';

import { App } from '../_class/app';

@Component({
  selector: 'app-site-toolbar',
  templateUrl: './site-toolbar.component.html',
  styleUrls: ['./site-toolbar.component.scss']
})
export class SiteToolbarComponent {
  title: string;
  title2: string;

  constructor(public app: App) {
    this.title = "MICSTO";
    this.title2 = "Team Developer Extensions";
  }

  ngOnInit(): void {

  }

  async logout() {
    await this.app.logout();
    window.location.reload();
  }
}
