<div class="main-content">
  <div class="main">
    <form *ngIf="!app.accessToken" #userlogin="ngForm" (ngSubmit)="login()">
      <div class="field-container-vert">

        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!name">
          <mat-label>User</mat-label>
          <input required matInput [(ngModel)]="name" name="username" autocomplete="username">
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!password">
          <mat-label>Password</mat-label>
          <input required matInput [(ngModel)]="password" name="password" type="password" autocomplete="new-password">
        </mat-form-field>

        <div class="container-horz-hcenter-vcenter">
          <button mat-raised-button color="primary" type="submit" id="submit">
            <mat-icon>login</mat-icon>
            Login
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="app.accessToken" class="field-container-vert">
      <div class="placeholder-text">You are already logged in</div>

      <div class="container-horz-hcenter-vcenter">
        <button mat-raised-button color="primary" (click)="app.logout()">
          <mat-icon>logout</mat-icon>
          Logout
        </button>
      </div>
    </div>
  </div>
</div>